import { template as template_8446740df0424d9d9c32eec72e779b33 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_8446740df0424d9d9c32eec72e779b33(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;

import { template as template_054d14bb98084448bfa2616b520fa56c } from "@ember/template-compiler";
const FKLabel = template_054d14bb98084448bfa2616b520fa56c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;

import { template as template_435063ca91754c61a7b868daef72276f } from "@ember/template-compiler";
const FKText = template_435063ca91754c61a7b868daef72276f(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
